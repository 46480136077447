<template>



   <v-card>
    <v-tabs
      v-model="tab"
      bg-color="primary"
    >
      <v-tab value="one">Неделя</v-tab>
      <v-tab value="two">День</v-tab>
      <v-tab value="three">график Оплат</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window :touch="{left: null, right: null}" v-model="tab">
        <v-window-item  value="one">
            <v-container>
              <v-row>
              <v-col cols="3">
                <v-text-field
                v-model="gosnomerS"
            label="Поиск по госномеру"
            
            
          ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn @click="SearchGos()">
  Поиск
</v-btn>
<v-btn @click="getFile()">
  Скачать загрузку на неделю
</v-btn>
<v-btn @click="getFileNextWeek()">
  Скачать загрузку на следующую неделю
</v-btn>
              </v-col>
              <v-col v-if="hisP.length != 0 || hisZ.length != 0 || hisZ.length != 0" cols="3">
                <v-btn @click="HistoryAuto=true">
  История
</v-btn>
              </v-col>
            </v-row>
    <v-row class="text-center">
      
      <v-col cols="12">
        <v-card>
        <h2 class="headline font-weight-bold mb-5">
          Запись на ремонт
        </h2>
      <div class="wrapper1">
    <div class="div1">
    </div>
</div>
        <div><vue-cal v-if="!isMobile()"
  :selected-date="today"
  :time-from="9 * 60"
  :time-to="20 * 60"
  style="height: 750px; overflow: scroll"
  hide-view-selector
  :hide-weekdays="[7]"
  :time-step="30"
  :disable-views="['years', 'year', 'month', 'day', ]"
  @cell-click="AddEvent($event, 1)"
:on-event-click="onEventClick"
  locale="ru"
  :events="events"
  :split-days="splitDays"
  sticky-split-labels
  show-time-in-cells
 :min-cell-width="minCellWidth"
  :time-cell-height="80"
  :min-split-width="minSplitWidth"
  >
</vue-cal> </div>
 
 <vue-cal v-if="isMobile()"
  :selected-date="today"
  :time-from="9 * 60"
  style="height: 750px"
  :time-to="20 * 60"
  hide-view-selector
  :hide-weekdays="[7]"
  :time-step="30"
  :disable-views="['years', 'year', 'month', 'day', ]"
  @cell-click="AddEvent($event, 1)"
:on-event-click="onEventClick"
  locale="ru"
  :events="events"
  :split-days="splitDays"
  sticky-split-labels
  show-time-in-cells
 :min-cell-width="250"
  :time-cell-height="80"
  
  >
</vue-cal> 


        </v-card>
      </v-col>

      <v-col cols="12">
        <h2 class="headline font-weight-bold mb-5">
          Выдача автомобилей
        </h2>
        <div class="wrapper2">
    <div class="div1">
    </div>
</div>
        <vue-cal
  :selected-date="today"
  hide-view-selector
  :hide-weekdays="[7]"
  style="height: 750px"
  :disable-views="['years', 'year', 'month', 'day', ]"
 @cell-click="AddEvent($event, 2)"
  locale="ru"
  :events="otpusk"
  :on-event-click="otpuskClick"
  :split-days="splitDays"
  :sticky-split-labels="stickySplitLabels"
  :min-cell-width="minCellWidth"
  :time-cell-height="100"
  :time="false"
  :min-split-width="minSplitWidth">
</vue-cal>
      </v-col>
      <v-col cols="12">
        <h2 class="headline font-weight-bold mb-5">
          Поступления Запасных частей
        </h2>
        <div class="wrapper3">
    <div class="div1">
    </div>
</div>
        <vue-cal
  :selected-date="today"

  style="height: 750px"
   @cell-click="AddEventZap($event)"
  hide-view-selector
  :hide-weekdays="[7]"
  :time-step="30"
  :disable-views="['years', 'year', 'month', 'day', ]"
:on-event-click="zapchastiClick"
  locale="ru"
  :events="zap"
  :split-days="splitDays"
  :time="false"
  :sticky-split-labels="stickySplitLabels"
  :min-cell-width="minCellWidth"
  :time-cell-height="50"
  :min-split-width="minSplitWidth">
</vue-cal>
      </v-col>
    </v-row>


    <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="350px"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="primary"
          v-bind="props"
        >
          Open Dialog
        </v-btn>
      </template>

      <v-card>
        <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="mark"
            label="Марка и модель"
            required
          ></v-text-field>
        </v-col>

       

        <v-col
          cols="12"
        >
          <v-text-field
            v-model="Gosnomer"
            label="ГосНомер"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          v-if="cal!=3"
        >
          <v-text-field 
            v-model="FIO"
            label="ФИО Клиента"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          v-if="cal!=3"
        >
          <v-text-field
            v-model="phone"
            label="Номер Телефона"
            required
          ></v-text-field>
          <v-text-field
            v-model="comment"
            label="Комментарий"
            required
          ></v-text-field>
          <v-text-field
            v-model="DateV"
            v-mask="'00.00.0000 00:00'"
            label="Планируемая дата выдачи"
            required
          ></v-text-field>
                    <v-checkbox v-model="osmotr" label="Осмотр"></v-checkbox>

        </v-col>
      </v-row>
    </v-container>
        <v-card-actions>
          
          <v-btn color="primary" block @click="TestEv()">Добавить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>


<!-- Всплывашка изменения даты -->
     <div class="text-center">
    <v-dialog
      v-model="dialogChangeDate"
      width="350px"
    >
      

      <v-card>
        <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="dateNew"
            label="Новая дата приемки автомобиля"
            v-mask="'00.00.0000 00:00'"
            required
          ></v-text-field>
          <span v-if="viewError">{{textError}}</span>
        </v-col>

        
      </v-row>
    </v-container>
        <v-card-actions>
          <v-btn color="primary" block @click="changeDate()">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <!-- Конец -->

    <!-- Всплывашка изменения даты выдачи -->
     <div class="text-center">
    <v-dialog
      v-model="dialogChangeDateV"
      width="350px"
    >
      

      <v-card>
        <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="dateNewV"
            label="Новая дата выдачи автомобиля"
            v-mask="'00.00.0000 00:00'"
            required
          ></v-text-field>
           <span v-if="viewError">{{textError}}</span>
        </v-col>

        
      </v-row>
    </v-container>
        <v-card-actions>
          
          <v-btn color="primary" block @click="changeDateV()">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <!-- Конец -->


    



<!-- Всплывашка запасных частей добавление -->
     <div class="text-center">
    <v-dialog
      v-model="dialogAddZap"
      width="350px"
    >
      

      <v-card>
        <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="mark"
            label="Марка и модель"
            required
          ></v-text-field>
        </v-col>

       

        <v-col
          cols="12"
        >
          <v-text-field
            v-model="Gosnomer"
            label="ГосНомер"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          v-if="cal!=3"
        >
        </v-col>
        
      </v-row>
    </v-container>
        <v-card-actions>
          <v-btn color="primary" block @click="AddZap()">Добавить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <!-- Конец добавления запасных частей -->

<!-- Всплывашка записи -->
  <div class="text-center">
    <v-dialog
      v-model="dialogInfo"
      width="350px"
    >
     

      <v-card>
         <v-card-text>
           Автомобиль: {{auto}}<br>
           ФИО клиента: {{FIO}}<br>
           Номер телефона: {{phone}}<br>
           Комментарий: {{comment}}
          <v-checkbox v-model="stat" @click="Priehala(id)" label="Машина приехала"></v-checkbox>
          <v-btn @click="dialogChangeDates()">Изменить время приемки</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue-darken-1"
            variant="text"
           @click="deleteP()"
          >
            Удалить
          </v-btn>
          <v-btn color="primary" block @click="dialogInfo=!dialogInfo">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
<!-- Конец -->

<!-- Всплывашка запчастей -->
<div class="text-center">
    <v-dialog
      v-model="dialogInfoZ"
      width="350px"
    >
     

      <v-card>
         <v-card-text>
           Автомобиль: {{auto}}<br>
           
           
          <v-checkbox v-model="statZ" @click="AddZapchasti(id)" label="Запчасти приехали"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="dialogInfoZ=!dialogInfoZ">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
<!-- Конец -->



<!-- Всплывашка запчастей -->
<div class="text-center">
    <v-dialog
      v-model="dialogInfoOplata"
      width="350px"
    >
     

      <v-card>
         <v-card-text>
           Автомобиль: {{auto}}<br>
           Заказ-Наряды: {{ text }}
           
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="dialogInfoOplata=!dialogInfoOplata">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
<!-- Конец -->


<!-- Всплывашка Выдачи -->
  <div class="text-center">
    <v-dialog
      v-model="dialogInfoV"
      width="350px"
    >
     

      <v-card>
         <v-card-text>
           Автомобиль: {{auto}}<br>
           ФИО клиента: {{FIO}}<br>
           Номер телефона: {{phone}}<br>
           Комментарий: {{comment}}
          <v-checkbox v-model="statV" @click="issued(id)" label="Машина выдана"></v-checkbox>
          <v-btn @click="sendNotification(id)">Уведомить клиента</v-btn>
  <v-btn @click="dialogChangeDatesV()">Изменить время приемки</v-btn>

        </v-card-text>
        <v-card-actions>
           <v-btn
            color="blue-darken-1"
            variant="text"
           @click="deleteV()"
          >
            Удалить
          </v-btn>
          <v-btn color="primary" block @click="dialogInfoV=!dialogInfoV">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
<!-- Конец -->




  </v-container>
        </v-window-item>

        <v-window-item value="two">
          <v-container>
            <v-row>
              <v-col cols="3">
                <v-text-field
                v-model="gosnomerS"
            label="Поиск по госномеру"
            
            
          ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn @click="SearchGos()">
  Поиск
</v-btn>
              </v-col>
              <v-col v-if="hisP.length != 0 || hisZ.length != 0 || hisZ.length != 0" cols="3">
                <v-btn @click="HistoryAuto=true">
  История
</v-btn>
              </v-col>
            </v-row>
            <!-- история автомобиля -->
            <div class="text-center">
    <v-dialog
      v-model="HistoryAuto"
      width="500px"
    >
     

      <v-card>
         <h2 class="headline font-weight-bold mb-5">
          Запись на ремонт
        </h2>
          <v-table>
           
    <thead>
      <tr>
        <th class="text-left">
          Автомобиль
        </th>
        <th class="text-left">
          Дата
        </th>
        <th class="text-left">
          Статус
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in hisP"
        :key="item.auto"
      >
        <td>{{item.title}}</td>
        <td>{{item.start}}</td>
        <td>{{item.status}}</td>
      </tr>
    </tbody>
  </v-table>
  <h2 class="headline font-weight-bold mb-5">
          Выдача автомобиля
        </h2>
  <v-table>
    <thead>
      <tr>
        <th class="text-left">
          Автомобиль
        </th>
        <th class="text-left">
          Дата
        </th>
        <th class="text-left">
          Статус
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in hisV"
        :key="item.auto"
      >
        <td>{{item.title}}</td>
        <td>{{item.start}}</td>
        <td>{{item.status}}</td>
      </tr>
    </tbody>
  </v-table>
  <h2 class="headline font-weight-bold mb-5">
         Запасные части
        </h2>
  <v-table>
    <thead>
      <tr>
        <th class="text-left">
          Автомобиль
        </th>
        <th class="text-left">
          Дата
        </th>
        <th class="text-left">
          Статус
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in hisZ"
        :key="item.auto"
      >
        <td>{{item.title}}</td>
        <td>{{item.start}}</td>
        <td>{{item.status}}</td>
      </tr>
    </tbody>
  </v-table>
        <v-card-actions>
          
          <v-btn color="primary" block @click="HistoryAuto=!HistoryAuto">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <!-- Истоия автомобиля -->
            <v-row v-if="!isMobile()">
              <v-col cols="4">
                <h2 class="headline font-weight-bold mb-5">
          Запись на ремонт
        </h2>
                 <vue-cal
  :selected-date="todayP"
  :time-from="9 * 60"
  :time-to="20 * 60"
  style="height: 750px"
  hide-view-selector
  :hide-weekdays="[7]"
  :time-step="30"
  active-view="day"
  :disable-views="['years', 'year', 'month',  ]"
  @cell-click="AddEvent($event, 1)"
:on-event-click="onEventClick"
  locale="ru"
  show-time-in-cells
  :events="events"
  :split-days="splitDays"
  :sticky-split-labels="stickySplitLabels"
  :min-cell-width="minCellWidth"
  :time-cell-height="75"
  :min-split-width="minSplitWidth">
</vue-cal>
          </v-col>
          
          <v-col cols="4">
            <h2 class="headline font-weight-bold mb-5">
          Выдача автомобилей
        </h2>
                 <vue-cal
  :selected-date="todayV"
  :time-from="9 * 60"
  :time-to="20 * 60"
  hide-view-selector
  :hide-weekdays="[7]"
  style="height: 750px"
  :time-step="30"
  active-view="day"
  :disable-views="['years', 'year', 'month',  ]"
  @cell-click="AddEvent($event, 2)"
:on-event-click="otpuskClick"
  locale="ru"
  :events="otpusk"
  :split-days="splitDays"
  show-time-in-cells
  :sticky-split-labels="stickySplitLabels"
  :min-cell-width="minCellWidth"
  :time-cell-height="75"
  :min-split-width="minSplitWidth">
</vue-cal>
          </v-col>
          <v-col cols="4">
            <h2 class="headline font-weight-bold mb-5">
          Поступления Запасных частей
        </h2>
                 <vue-cal
  :selected-date="todayZ"
  :time-from="9 * 60"
  :time-to="20 * 60"
  hide-view-selector
  :hide-weekdays="[7]"
  style="height: 750px"
  :time-step="30"
  show-time-in-cells
  active-view="day"
  :disable-views="['years', 'year', 'month', ]"
  @cell-click="AddEventZap($event)"
:on-event-click="zapchastiClick"
  locale="ru"
  :events="zap"
  :split-days="splitDays"
  :sticky-split-labels="stickySplitLabels"
  :min-cell-width="minCellWidth"
  :time-cell-height="75"
  :min-split-width="minSplitWidth">
</vue-cal>
          </v-col>
      </v-row>

    
  
<v-carousel style="" v-if="isMobile()" hide-delimiters :show-arrows="false">
  <v-carousel-item
   
    cover
  >
 
          <h2 class="headline font-weight-bold mb-5">
          Запись на ремонт
        </h2>
                 <vue-cal
  :selected-date="todayP"
  :time-from="9 * 60"
  :time-to="20 * 60"
  hide-view-selector
  :hide-weekdays="[7]"
  :time-step="30"
  show-time-in-cells
  active-view="day"
  :disable-views="['years', 'year', 'month',  ]"
  @cell-click="AddEvent($event, 1)"
:on-event-click="onEventClick"
  locale="ru"
  :events="events"
  :split-days="splitDays"
  :sticky-split-labels="stickySplitLabels"
  :min-cell-width="minCellWidth"
  :time-cell-height="50"
  :min-split-width="minSplitWidth">
</vue-cal>

  </v-carousel-item>

  <v-carousel-item
   
    cover
  >

           <h2 class="headline font-weight-bold mb-5">
          Выдача автомобилей
        </h2>
                 <vue-cal
  :selected-date="todayP"
 
  hide-view-selector
  :hide-weekdays="[7]"
  :time-step="30"
  :time="false"
    active-view="day"
  :disable-views="['years', 'year', 'month',  ]"
  @cell-click="AddEvent($event, 2)"
:on-event-click="otpuskClick"
  locale="ru"
  :events="otpusk"
  :split-days="splitDays"
  :sticky-split-labels="stickySplitLabels"
  :min-cell-width="minCellWidth"
  :time-cell-height="50"
  :min-split-width="minSplitWidth">
</vue-cal>
  </v-carousel-item>

  <v-carousel-item
    
    cover
  >

            <h2 class="headline font-weight-bold mb-5">
          Поступления
        </h2>
                 <vue-cal
  :selected-date="todayP"
  :time-from="9 * 60"
  :time-to="20 * 60"
  hide-view-selector
  :hide-weekdays="[7]"
  :time-step="30"
  show-time-in-cells
  active-view="day"
  :disable-views="['years', 'year', 'month', ]"
  @cell-click="AddEventZap($event)"
:on-event-click="zapchastiClick"
  locale="ru"
  :events="zap"
  :split-days="splitDays"
  :sticky-split-labels="stickySplitLabels"
  :min-cell-width="minCellWidth"
  :time-cell-height="50"
  :min-split-width="minSplitWidth">
</vue-cal>

  </v-carousel-item>
  <v-card></v-card>
</v-carousel>
    

    </v-container>
        </v-window-item>


       <v-window-item value="three"
    >

    <vue-cal
  :selected-date="todayP"
  
  show-time-in-cells
  active-view="month"
  :disable-views="['years', 'year']"
  :time="false"
  :split-days="splitDay"
  :sticky-split-labels="stickySplitLabels"
  :on-event-click="ClickOplata"

  locale="ru"
  :events="grafik"
 style="height: 600px"
>
</vue-cal>


    <v-container>
      </v-container>
    </v-window-item>



      </v-window>
    </v-card-text>
  </v-card>



</template>

<script>
/* eslint-disable */
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import $ from 'jquery'
import axios from 'axios'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
export default {
  name: 'HelloWorld',
  components: { VueCal },
  data: () => ({
    stickySplitLabels: true,
  minCellWidth: 500,
  dialog: false,
  cal: 0,
  hisP: [],
  grafik: [],
  hisV:[],
  hisZ:[],
  dateNew: '',
  mark: '',
  dialogInfoOplata: false,
  text: '',
  DateV: '',
  viewError: false,
  textError: '',
  dateNewV: '',
  dialogChangeDateV: false,
  dialogInfoV:false,
  onboarding: 0,
  dialogInfo:false,
  Gosnomer: '',
  date: '',
  dialogInfoZ: false,
  statZ: false,
  osmotr: false,
  stat: false,
  dialogChangeDate: false,
  statV: false,
  id: '',
  phone: '',
  gosnomerS: '',
  todayP: new Date(),
  todayV: new Date(),
  todayZ: new Date(),
  FIO: '',
  dialogAddZap: false,
   tab: null,
  minSplitWidth: 0,
  comment: '',
  HistoryAuto: false,
  auto: '',

  today: new Date(),
  splitDays: [
    // The id property is added automatically if none (starting from 1), but you can set a custom one.
    // If you need to toggle the splits, you must set the id explicitly.
    { id: 1, class: 'mom', label: 'Ирек' },
    { id: 2, class: 'dad', label: 'Виктор', },
    { id: 3, class: 'kid3', label: 'Механика', },
 
  ],
  splitDay: [
    // The id property is added automatically if none (starting from 1), but you can set a custom one.
    // If you need to toggle the splits, you must set the id explicitly.
    { id: 1, class: 'mom', label: 'Оплата' },
    { id: 2, class: 'dad', label: 'Счета', },
 
  ],
  
  events: [
  ],
  otpusk: [
  ],
  zap: [
  ],

  }),
  mounted() {
    let self =this
                setInterval(() => {
self.events = []
        self.otpusk = []
        self.zap = []
        self.grafik = []
        self.getEvents()
        
                        }, 60000); // reload every 10 seconds
            },
  created(){
    let self = this
    self.getEvents();
    $(function(){
    $(".wrapper1").scroll(function(){
        $(".vuecal--overflow-x.vuecal--day-view .vuecal__cells, .vuecal--overflow-x.vuecal--week-view .vuecal__cells")
            .scrollLeft($(".wrapper1").scrollLeft());
    });
    $(".vuecal--overflow-x.vuecal--day-view .vuecal__cells, .vuecal--overflow-x.vuecal--week-view .vuecal__cells").scroll(function(){
        $(".wrapper1")
            .scrollLeft($(".vuecal--overflow-x.vuecal--day-view .vuecal__cells, .vuecal--overflow-x.vuecal--week-view .vuecal__cells").scrollLeft());
    });
});
$(function(){
    $(".wrapper2").scroll(function(){
        $(".vuecal--overflow-x.vuecal--day-view .vuecal__cells, .vuecal--overflow-x.vuecal--week-view .vuecal__cells")
            .scrollLeft($(".wrapper2").scrollLeft());
    });
    $(".vuecal--overflow-x.vuecal--day-view .vuecal__cells, .vuecal--overflow-x.vuecal--week-view .vuecal__cells").scroll(function(){
        $(".wrapper2")
            .scrollLeft($(".vuecal--overflow-x.vuecal--day-view .vuecal__cells, .vuecal--overflow-x.vuecal--week-view .vuecal__cells").scrollLeft());
    });
});
$(function(){
    $(".wrapper3").scroll(function(){
        $(".vuecal--overflow-x.vuecal--day-view .vuecal__cells, .vuecal--overflow-x.vuecal--week-view .vuecal__cells")
            .scrollLeft($(".wrapper3").scrollLeft());
    });
    $(".vuecal--overflow-x.vuecal--day-view .vuecal__cells, .vuecal--overflow-x.vuecal--week-view .vuecal__cells").scroll(function(){
        $(".wrapper3")
            .scrollLeft($(".vuecal--overflow-x.vuecal--day-view .vuecal__cells, .vuecal--overflow-x.vuecal--week-view .vuecal__cells").scrollLeft());
    });
});
  },
  methods: {
    getEvents(){
      let self = this
      axios
      .get('https://server.euromotorsufa.ru/api/getPriem')
      .then((response) => {
        response.data.forEach((element) => {
          let date1 = new Date(element.end)
              let date2 = new Date();
             if(element.status === null && date1.getTime()>date2.getTime()  ){
            console.log(element.title)
            self.events.push({
              id: element.id,
              start: element.start,
              end: element.end,
              status: false,
              title: element.title,
              class: element.class,
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
            }else if(element.status != 1 && date1.getTime()>date2.getTime()){
                console.log(element.title)
            self.events.push({
              id: element.id,
              start: element.start,
              end: element.end,
              status: false,
              title: element.title,
              class: element.class,
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
            }else if(element.status == '1'){
                self.events.push({
                  id: element.id,
              start: element.start,
              end: element.end,
              title: element.title,
              class: 'health',
              status: true,
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
            }
            if(date1.getTime()<date2.getTime() && element.status != '1'){
              

               self.events.push({
                 id: element.id,
              start: element.start,
              end: element.end,
              title: element.title,
              class: 'leisure',
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
              
            }
          });
        console.log(self.events);
      })
      axios
      .get('https://server.euromotorsufa.ru/api/getZap')
      .then((response) => {
        response.data.forEach((element) => {
          let date1 = new Date(element.end)
              let date2 = new Date();
            if(element.status === null && date1.getTime()>date2.getTime()  ){
            self.zap.push({
              id: element.id,
              start: element.start,
              end: element.end,
              status: false,
              title: element.title,
              class: element.class,
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
            }else if(element.status != 1 && date1.getTime()>date2.getTime()){
            self.zap.push({
              id: element.id,
              start: element.start,
              end: element.end,
              status: false,
              title: element.title,
              class: element.class,
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
            }else if(element.status == '1'){
                self.zap.push({
                  id: element.id,
              start: element.start,
              end: element.end,
              title: element.title,
              class: 'health',
              status: true,
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
            }else if(date1.getTime()<date2.getTime() && element.status != '1'){
              

               self.zap.push({
                 id: element.id,
              start: element.start,
              end: element.end,
              title: element.title,
              class: 'leisure',
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
              
            }
          });
      })
      axios
      .get('https://server.euromotorsufa.ru/api/getOtpusk')
      .then((response) => {
       response.data.forEach((element) => {

            let date1 = new Date(element.end)
              let date2 = new Date();
            if(element.status === null && date1.getTime()>date2.getTime()  ){
            console.log(element.title)
            self.otpusk.push({
              id: element.id,
              start: element.start,
              end: element.end,
              status: false,
              title: element.title,
              class: element.class,
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
            }else if(element.status != 1 && date1.getTime()>date2.getTime()){
                console.log(element.title)
            self.otpusk.push({
              id: element.id,
              start: element.start,
              end: element.end,
              status: false,
              title: element.title,
              class: element.class,
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
            }else if(element.status == '1'){
                self.otpusk.push({
                  id: element.id,
              start: element.start,
              end: element.end,
              title: element.title,
              class: 'health',
              status: true,
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
            }else if(date1.getTime()<date2.getTime() && element.status != '1'){
              

               self.otpusk.push({
                 id: element.id,
              start: element.start,
              end: element.end,
              title: element.title,
              class: 'leisure',
              split:  Number(element.split),
              FIO: element.FIO,
              phone:element.phone,
              comment: element.comment,
              
            })
              
            }
          });
          console.log(self.otpusk)
      })




      axios
      .get('https://server.euromotorsufa.ru/api/grafiks')
      .then((response) => {
       response.data.forEach((element) => {

            
             if(element.split == '1'){
            self.grafik.push({
              id: element.id,
              start: element.start,
              end: element.end,
              title: element.title,
              text: element.content,
              class: element.class,
              split: Number(element.split)
              
            })
             }
            
          });
          console.log(self.otpusk)
      })


      axios
      .get('https://server.euromotorsufa.ru/api/day')
      .then((response) => {
       response.data.forEach((element) => {

            
             if(element.split == '2'){
            self.grafik.push({
              id: element.id,
              start: element.start,
              end: element.end,
              title: element.title,
              class: element.class,
              split: Number(element.split)
              
            })
             }
            
          });
          console.log(self.otpusk)
      })
    },
    onEventClick (event) {
      
    console.log(event)
    let self = this;
    self.auto = event.title;
    self.FIO = event.FIO;
    self.phone = event.phone;
    self.comment = event.comment;
    self.id = event.id;
    self.stat = event.status;
    self.dialogInfo = !self.dialogInfo
      
  },
  otpuskClick(event){
let self = this;
console.log(event.id)
    self.auto = event.title;
    self.FIO = event.FIO;
    self.phone = event.phone;
    self.comment = event.comment;
    self.id = event.id;
    self.statV = event.status;
    self.dialogInfoV = !self.dialogInfoV
  },
  zapchastiClick(event){
let self = this;
console.log(event.id)
    self.auto = event.title;
    self.id = event.id;
    self.statZ = event.status;
    self.dialogInfoZ = !self.dialogInfoZ
  },
  ClickOplata(event){
let self = this;
if(event.split == 1){
    self.auto = event.title;
    self.id = event.id;
    self.text = event.text;
    self.dialogInfoOplata = !self.dialogInfoOplata
}
  },

    Priehala(id){
       let self = this;
      self.stat = !self.stat;
      
      axios
      .post('https://server.euromotorsufa.ru/api/autoPriem',{
        status: self.stat,
        id: self.id
      })
      .then((response) => {
        self.events = []
        self.otpusk = []
        self.zap = []
        self.getEvents()
      })
    },
    AddZapchasti(id){
       let self = this;
      self.statZ = !self.statZ;
      
      axios
      .post('https://server.euromotorsufa.ru/api/sendNotificationOnGroup',{
        status: self.statZ,
        id: self.id
      })
      .then((response) => {
        self.events = []
        self.otpusk = []
        self.zap = []
        self.getEvents()
      })
    },
    issued(id){
       let self = this;
      self.statV = !self.statV;
      
      axios
      .post('https://server.euromotorsufa.ru/api/autoIssued',{
        status: self.statV,
        id: self.id
      })
      .then((response) => {
        self.events = []
        self.otpusk = []
        self.zap = []
        self.getEvents()
      })
    },



    AddEvent(date, cal){
      let self = this
      self.dialog = !self.dialog;
      self.date = date
      console.log(date.date.toLocaleTimeString().slice(0,-3))
      let dateTo1 = date.date.toLocaleTimeString().slice(0,-3).split(':')
      let dateFrom = ''
      let dateTo = ''
      if(Number(dateTo1[1])<30){
        dateTo = dateTo1[0] + ':' + '00'
        dateFrom = dateTo1[0] + ':' + '30'

      }else{
        dateTo = dateTo1[0] + ':' + '30'
        dateFrom = (Number(dateTo1[0]) + 1) + ':' + '00'
      }
      console.log(dateTo + ' ' + dateFrom)
      console.log(date.date.toLocaleDateString())
      self.cal = cal
    },
    AddEventZap(date){
      let self = this
      self.dialogAddZap = !self.dialogAddZap;
      self.date = date
      console.log(date.date.toLocaleTimeString().slice(0,-3))
      let dateTo1 = date.date.toLocaleTimeString().slice(0,-3).split(':')
      let dateFrom = ''
      let dateTo = ''
      if(Number(dateTo1[1])<30){
        dateTo = dateTo1[0] + ':' + '00'
        dateFrom = dateTo1[0] + ':' + '30'

      }else{
        dateTo = dateTo1[0] + ':' + '30'
        dateFrom = (Number(dateTo1[0]) + 1) + ':' + '00'
      }
      console.log(dateTo + ' ' + dateFrom)
      console.log(date.date.toLocaleDateString())
      
    },
    isMobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true;
      }
      else{
        return false;
      }
    },

    sendNotification(id){
      let self = this
         axios
      .post('https://server.euromotorsufa.ru/api/sendNotification',{
        id: self.id
      })
      .then((response) => {
       
      })
    },

AddZap(){
    let dateTo1 = this.date.date.toLocaleTimeString().slice(0,-3).split(':')
    
      let dateFrom = ''
      let dateTo = ''
      if(Number(dateTo1[1])<30){
        dateTo = dateTo1[0] + ':' + '00'
        dateFrom = dateTo1[0] + ':' + '30'

      }else{
        dateTo = dateTo1[0] + ':' + '30'
        dateFrom = (Number(dateTo1[0]) + 1) + ':' + '00'
      }
      let dateing = this.date.date.toLocaleDateString().split('.')
     
      let classE = 'sport'
      

    
     axios
      .post('https://server.euromotorsufa.ru/api/AddZap', {
        start: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateTo,
         end: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateFrom,
        title: this.mark + ' ' + this.Gosnomer,
        class: classE,
        server: "web",
        split: this.date.split
      })
      .then((response) => {
        console.log(response)
      })



    this.zap.push({
      start: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateTo,
      end: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateFrom,
      title: this.mark + ' ' + this.Gosnomer,
      class: classE,
      split: this.date.split
    })
    

    this.dialogAddZap = !this.dialogAddZap
    this.mark = '';
    this.Gosnomer = ''
    this.FIO = '';
    this.phone = ''
  },

    TestEv(){
    let dateTo1 = this.date.date.toLocaleTimeString().slice(0,-3).split(':')
    
      let dateFrom = ''
      let dateTo = ''
      if(Number(dateTo1[1])<30){
        dateTo = dateTo1[0] + ':' + '00'
        dateFrom = dateTo1[0] + ':' + '30'

      }else{
        dateTo = dateTo1[0] + ':' + '30'
        dateFrom = (Number(dateTo1[0]) + 1) + ':' + '00'
      }
      let dateing = this.date.date.toLocaleDateString().split('.')
      let classE = ''
      
        classE = 'sport'
      
    if(this.cal == 1){
     axios
      .post('https://server.euromotorsufa.ru/api/AddPriem', {
        start: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateTo,
         end: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateFrom,
        title: this.mark + ' ' + this.Gosnomer,
        class: classE,
        split: this.date.split,
        phone: this.phone,
        server: "web",
        osmotr: this.osmotr,
        FIO: this.FIO,
        comment: this.comment,
        dateV: this.DateV
      })
      .then((response) => {
        this.events = []
        this.otpusk = []
        this.zap = []
        this.getEvents()
              })



    
    }

if(this.cal == 2){
     axios
      .post('https://server.euromotorsufa.ru/api/AddOtpusk', {
        start: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateTo,
         end: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateFrom,
        title: this.mark + ' ' + this.Gosnomer,
        class: classE,
        server: "web",
        split: this.date.split,
        phone: this.phone,
        FIO: this.FIO
      })
      .then((response) => {
        console.log(response)
      })



    this.otpusk.push({
      start: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateTo,
      end: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateFrom,
      title: this.mark + ' ' + this.Gosnomer,
      class: classE,
      split: this.date.split,
      phone: this.phone,
        FIO: this.FIO
    })
    }

    if(this.cal == 3){
     axios
      .post('https://server.euromotorsufa.ru/api/AddZap', {
        start: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateTo,
         end: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateFrom,
        title: this.mark + ' ' + this.Gosnomer,
        class: classE,
        split: this.date.split
      })
      .then((response) => {
        console.log(response)
      })



    this.zap.push({
      start: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateTo,
      end: dateing[2] + '-' + dateing[1] + '-' + dateing[0] + ' ' + dateFrom,
      title: this.mark + ' ' + this.Gosnomer,
      class: classE,
      split: this.date.split
    })
    }

    this.dialog = !this.dialog
    this.mark = '';
    this.Gosnomer = ''
    this.FIO = '';
    this.phone = ''
  },
  
  dialogChangeDates(){
    let self = this
    this.dialogInfo = false
    this.dialogChangeDate = true
  },
  changeDate(){
    let self = this
    axios.post('https://server.euromotorsufa.ru/api/changeDateP', {
      date: this.dateNew,
      id: this.id
    })
    .then((response) => {
      if(response.data.error){
          self.textError = response.data.text
          self.viewError = true;
      }else{
      self.dialogChangeDate = false
        self.events = []
        self.otpusk = []
        self.zap = []
        self.getEvents()
      }
      })
    
    
  },
  
  dialogChangeDatesV(){
    let self = this
    this.dialogInfoV = false
    this.dialogChangeDateV = true
  },
  deleteP(id){
    let self = this
    axios.post('https://server.euromotorsufa.ru/api/deleteDateP', {
      id: self.id
    }).then((response) => {
      self.dialogInfo = false;
      self.events = []
        self.otpusk = []
        self.zap = []
        self.getEvents()
    })
  },
  deleteV(id){
let self = this
    axios.post('https://server.euromotorsufa.ru/api/deleteDateV', {
      id: self.id
    }).then((response) => {
      self.dialogInfoV = false;
      self.events = []
        self.otpusk = []
        self.zap = []
        self.getEvents()
    })
  },
  SearchGos(){
    let self = this
    axios.post('https://server.euromotorsufa.ru/api/searchGosnomer', {
      gosnomer: self.gosnomerS
    })
    .then((response) => {
      if(response.data.p !== null){
        self.todayP = response.data.p.start
      }
      if(response.data.v !== null){
        self.todayV = response.data.v.start
      }
      if(response.data.z !== null){
        self.todayZ = response.data.z.start
      }
      })



      axios.post('https://server.euromotorsufa.ru/api/searchGosnomerHistory', {
      gosnomer: self.gosnomerS
    })
    .then((response) => {
      if(response.data.p !== null){
        self.hisP = response.data.p
      }
      if(response.data.v !== null){
        self.hisV = response.data.v
      }
      if(response.data.z !== null){
        self.hisZ = response.data.z
      }
      })
       self.tab = "two"
  },
  changeDateV(){
    let self = this
    axios.post('https://server.euromotorsufa.ru/api/changeDateV', {
      date: this.dateNewV,
      id: this.id
    })
    .then((response) => {
      if(response.data.error){
          self.textError = response.data.text
          self.viewError = true;
      }else{
      self.dialogChangeDateV = false
        self.events = []
        self.otpusk = []
        self.zap = []
        self.getEvents()
      }
      })
  },
  getFile(){
    window.location.href = 'https://server.euromotorsufa.ru/api/getGrafWeek'; //Will take you to Google.

  },
  getFileNextWeek(){
    window.location.href = 'https://server.euromotorsufa.ru/api/getGrafWeekNext';
  }
  },
  
  
}
</script>
<style type="text/css">
.vuecal__event.sport {
    background-color: grey;
    color: #fff;
    font-weight: 700;
    border: 10px white;
    margin: 0 0 5px 0;
}
.vuecal__cell-split.dad {background-color: rgba(221, 238, 255, 0.5);}
.vuecal__cell-split.mom {background-color: rgba(255, 232, 251, 0.5);}
.vuecal__cell-split.kid1 {background-color: rgba(221, 255, 239, 0.5);}
.vuecal__cell-split.kid2 {background-color: rgba(255, 250, 196, 0.5);}
.vuecal__cell-split.kid3 {background-color: rgba(255, 206, 178, 0.5);}
.vuecal__cell-split .split-label {color: rgba(0, 0, 0, 0.1);font-size: 26px;}
.vuecal__cell-split .split-label {color: rgba(0, 0, 0, 0.1);font-size: 26px;    border-style: groove;}
.vuecal__split-days-headers .day-split-header {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-style: groove;
    
}
.vuecal__cell-split {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    position: relative;
    transition: .15s ease-in-out background-color;
    border-style: groove;
    border-right-style: solid;
}
.vuecal__heading .weekday-label {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: groove;
    border-right-style: solid;
}
/* Different color for different event types. */
.vuecal__event.leisure {background-color: rgba(253, 88, 66, 0.9);color: #fff;}
.vuecal__event.health {background-color: rgba(136, 255, 136, 0.9);}

.vuecal__time-column .vuecal__time-cell {
    color: #000;
    text-align: right;
    padding-right: 2px;
    font-size: 0.9em;
    font-weight: bold;
}
.vuecal__cell .cell-time-label {
    flex-grow: 1;
    font-size: .8em;
    opacity: 1;
    line-height: 1.7;
}
.vuecal--overflow-x.vuecal--week-view.vuecal--sticky-split-labels .vuecal__time-column {
    margin-top: 3.4em;
    border-left-style: groove;
}
.v-container {
    max-width: 3500px;
}
.wrapper1, .wrapper2, .wrapper3{width: 100%; border: none 0px RED;
overflow-x: auto; overflow-y:hidden;}
.wrapper3{height: 20px; }
.div1 {width: 3140px;
    height: 20px; }
.div2 {width:1000px; height: 200px; background-color: #88FF88;
overflow: hidden;}


.vuecal *, .vuecal--has-touch :not(.vuecal__event-title--edit) {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-weight: 600;
}

.vuecal__cell-events-count {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translate(-50%);
    min-width: 12px;
    height: 12px;
    line-height: 12px;
    padding: 0 3px;
    background: #196aff;
    color: #fff;
    border-radius: 12px;
    font-size: 10px;
    box-sizing: border-box;
}
.vuecal__cell:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: -1px;
    bottom: -1px;
    border: 1.5px solid black;
}

</style>